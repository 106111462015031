<template>
    <div style="min-height:200px" class="zcxy zl-list">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <div style="position:relative" class="clearfix table-tools">
            <div class="buttons roleAuthBtn">
                <a-button @click="toSave" :loading="saveLoading" v-show='this.roleId !=1 ' v-if='list.length>0' type="primary">保存</a-button>
            </div>
        </div>
        <a-tabs v-if='list.length>0' v-model="activeKey"  >
            <a-tab-pane  key="1" tab="功能模块">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <div class="roleTree">
                    <a-tree
                        default-expand-all
                        v-model="checkedKeys1"
                        checkable
                        :replace-fields='replaceFields'
                        :tree-data="[list[0]]"
                        @check='onCheck1'
                    />
                </div>
            </a-tab-pane>
            <a-tab-pane  v-if='list[1]' key="2" tab="数据分析">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <div class="roleTreeF">
                    <a-tree
                        default-expand-all
                        v-model="checkedKeys2"
                        checkable
                        :replace-fields='replaceFields'
                        :tree-data="[list[1]]"
                        @check='onCheck2'
                    />
                </div>
            </a-tab-pane>
        </a-tabs>
        <l-Empty v-if="list.length == 0"/>
    </div>
</template>

<script>
    export default {
        name:'RoleAuth',
        inject: ['parent'],
        data() {
            return {
                loading:false,
                saveLoading:false,
                list: [],
                activeKey:'1',
                rules1:[],
                rules2:[],
                checkedKeys1:[],
                checkedKeys2:[],
                halfCheckedKeys1:[],
                halfCheckedKeys2:[],
                replaceFields:{
                    key:'id',
                }
            }
        },
        props:['isTab','rules','keyPath','roleId','isList'],
        created () {
            if(this.$route.params.type_id == 1 && this.roleId && this.roleId !=1){
                this.getRule()
            }
        },
        methods: {
            async getRule() {
                this.list = []
                this.loading = true
                let res = await this.$store.dispatch('employeeRuleAction', {data:{type_id: this.isTab}})
                this.list = res.data
                let rules1 = await this.roleFilter(this.rules.data,1)
                let rules2 = await this.roleFilter(this.rules.bi,1)
                this.checkedKeys1= rules1
                this.checkedKeys2= rules2
                this.rules1 = this.rules.data
                this.rules2 = this.rules.bi
                this.loading = false
            },
            onCheck1(checkedKeys,info) {
                this.checkedKeys1 = checkedKeys
                this.halfCheckedKeys1 = info.halfCheckedKeys
                this.rules1 = []
            },
            onCheck2(checkedKeys,info) {
                this.checkedKeys2 = checkedKeys
                this.halfCheckedKeys2 = info.halfCheckedKeys
                this.rules2 = []
            },
            async toSave(){
                let obj = {
                    group_id:this.roleId.toString(),
                    rules:[],
                }
                let rules1 = []
                let rules2 = []
                if(this.rules1.length > 0){
                    rules1 = [...this.rules1]
                }else{
                    rules1 = [...this.checkedKeys1, ...this.halfCheckedKeys1]
                }
                if(this.rules2.length > 0){
                    rules2 = [...this.rules2]
                }else{
                    rules2 = [...this.checkedKeys2, ...this.halfCheckedKeys2]
                }
                obj.rules = [...rules1,...rules2]
                this.saveLoading = true
                await this.$store.dispatch('employeeGroupUpdateAction', {data:obj})
                .then(res=>{
                    this.parent.updateList()
                    this.$message.success('操作成功!')
                    this.saveLoading = false
                })
            },
            roleFilter(rules,num){
                let list = this.list
                let arr = []
                list.forEach((item)=>{
                    item.children.forEach((it)=>{
                        rules.forEach((i)=>{
                            if(it.id == i){
                                arr.push(it)
                            }
                        })
                    })
                })
                let arr2 = []
                arr.forEach((item)=>{
                    let arr3 = []
                    item.children.forEach((it)=>{
                        rules.forEach((i)=>{
                            if(it.id == i){
                                // arr2.push(i)
                                // arr3.push(i)
                                let obj = this.filterItem(it,rules)
                                arr2 = [...arr2,...obj.arr,...obj.partent]
                                arr3 = [...arr3,...obj.partent]
                            }
                        })
                    })
                    if(arr3.length == item.children.length){
                        arr2.push(item.id)
                    }
                })
                return arr2
            },
            filterItem(item,rules){
                let obj = {
                    arr:[],
                    partent:[]
                }
                item.children.forEach(it=>{
                    rules.forEach((i)=>{
                        if(it.id == i){
                            obj.arr.push(i)
                        }
                    })
                })
                if(obj.arr.length == item.children.length){
                    obj.partent.push(item.id)
                }
                return obj
            },
        },
        watch: {
            keyPath(newValue, oldValue) {
                let rules1 = this.roleFilter(this.rules.data,2)
                let rules2 = this.roleFilter(this.rules.bi,2)
                this.checkedKeys1= rules1
                this.checkedKeys2= rules2
                this.rules1 = this.rules.data
                this.rules2 = this.rules.bi
            },
            isList(newValue){
                if(newValue == 1){
                    this.getRule()
                }
            }
        },
    }
</script>

<style lang="scss">
    .roleTree{
        .ant-tree-switcher_open{
            // display: none !important;
        }
        ul{
            ul{
                ul{
                    ul{
                        display: flex;
                        align-items: center;
                        flex-wrap:wrap;
                    }
                }
            }
        }
    }
    .roleTreeF{
        .ant-tree-switcher_open{
            // display: none !important;
        }
        ul{
            ul{
                ul{
                    display: flex;
                    align-items: center;
                    flex-wrap:wrap;
                }
            }
        }
    }
    .roleAuthBtn{
        position:absolute;
        right: 10px;
        z-index: 10;
    }
</style>