<template>
    <div>
        <a-tabs default-active-key="1"  >
            <a-tab-pane key="1" tab="角色员工">
                <RoleStaff :isTab='isTab' :roleId='roleId'/>
            </a-tab-pane>
            <a-tab-pane v-if="roleId !=1" key="2" tab="角色权限" force-render>
                <RoleAuth :isTab='isTab' :rules='rules' :isList='isList' :keyPath='keyPath' :roleId='roleId'/>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import RoleStaff from './RoleStaff'
import RoleAuth from './RoleAuth'
    export default {
        name:"rightTab",
        inject: ['parent'],
        provide() {
            return {
            parent: this
            }
        },
        props:['isTab','rules','keyPath','roleId','isList'],
        components:{
            RoleStaff,
            RoleAuth
        },
        methods: {
            updateList() {
                this.parent.updateList()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>