<template>
    <div class="bizParam">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>通用设置</a-breadcrumb-item>
            <a-breadcrumb-item>{{title}}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box systemMeun">
          <div style="width:200px;min-height:200px;max-height:800px;position:relative">
            <!-- <a-button type="primary" @click="showRoleOperation" icon="plus" >新建角色</a-button> -->
            <div @click="showRoleOperation()" class="systemMeun-left-nav">
              <a-icon type="plus" />
              <span style="margin-left:10px">添加角色</span>
            </div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-menu v-model="keyPath" @click="handleClick" @mouseleave="handleOut">
              <a-menu-item @mouseover='handleOver(index)' v-for="(item,index) in list" :key="index">
                <!-- <a-row>
                  <a-col :span="18">
                    {{item.name}} ({{item.count}})
                  </a-col>
                  <a-col v-if="mouseIndex == index && item.name !='超级管理员角色'" :span="2" :offset="4">
                    <a-dropdown :trigger="['click']" placement="bottomCenter">
                      <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                        <a-icon type="more" />
                      </a>
                      <a-menu slot="overlay" @click="onClick">
                        <a-menu-item key="1">
                          编辑
                        </a-menu-item>
                        <a-menu-item key="2">
                          删除
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </a-col>
                </a-row> -->

                <div class="systemMeun-left-item" >
                  <span>{{item.name}}</span>
                  <a-dropdown :trigger="['click']" placement="bottomCenter" class='systemMeun-left-item-icon'>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                      <a-icon type="more" />
                    </a>
                    <a-menu slot="overlay" @click="onClick">
                      <a-menu-item key="1">
                        编辑
                      </a-menu-item>
                      <a-menu-item key="2">
                        删除
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                  <span class="systemMeun-left-item-count">( {{item.count}} )</span>
                </div>


              </a-menu-item>
            </a-menu>
            <l-Empty v-if="list.length == 0"/>
          </div>
          <div style="flex:1;padding-left:25px">
              <rightTab :isTab='isTab' :rules='rules' :isList='isList' :roleId='roleId' :keyPath='keyPath'/>
          </div>
        </div>
        <RoleOperation v-if="isRoleOperation" :ModalTitle='ModalTitle' :roleId='roleId' :isEdit='isEdit' :isTab='isTab' :roleName='roleName'/>
    </div>
</template>

<script>
import rightTab from '../modal/rightTab'
import RoleOperation from '../modal/RoleOperation'
export default {
  name: 'system',
  provide() {
    return {
      parent: this
    }
  },
  components: {
      rightTab,
      RoleOperation
  },
  data() {
    return {
        tabPosition: 'left',
        keyPath:[0],
        loading:false,
        list:[],
        rules:[],
        isTab:1,
        isList:0,
        title:'',
        isEdit:0,
        roleId:null,
        roleName:'',
        ModalTitle:'新建角色',
        mouseIndex:-1,
        isRoleOperation:false
    }
  },
  created () {
      this.title = this.$route.params.title
      this.isTab = this.$route.params.type_id
      this.getList()
  },
  
  methods:{
    async getList(){
        this.loading = true
        let index = this.keyPath[0]
        this.isList = 0
        let res = await this.$store.dispatch('employeeGroupAction', {data:{type_id:this.isTab}})
        if(res.data.length != 0){
          this.rules = res.data[index].rules
          this.roleId = res.data[index].id
        }else{
          this.rules = {
            data:[],
            bi:[]
          }
          this.roleId = -1
        }
        this.isList = 1
        this.list = res.data
        this.loading = false
    },
    handleClick(e) {
      let index = e.keyPath[0]
      this.rules = this.list[index].rules
      this.roleId = this.list[index].id
      this.keyPath = e.keyPath
    },
    showRoleOperation(){
      this.isEdit = 0
      this.isRoleOperation = true;
    },
    hideRoleOperation(num){
      if(num == 1){
        this.getList();
      }
      this.isRoleOperation = false;
    },
    handleOver(index){
      this.mouseIndex = index
    },
    handleOut(index){
      // this.mouseIndex = -1
    },
    onClick({ key }) {
      let that = this;
      let roleId = this.list[this.mouseIndex].id
      if(key == 1){
        this.isEdit = 1
        this.ModalTitle = '编辑角色'
        this.roleName = this.list[this.mouseIndex].name
        this.roleId = roleId
        this.isRoleOperation = true
        return false
      }
      this.$confirm({
        title: `您确定要删除该项?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('employeeGroupDeleteAction', { data: { group_id: roleId} })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    updateList(){
      this.getList()
    }
  },
  beforeRouteUpdate(to, from, next){
    this.rules = {
            data:[],
            bi:[]
    }
    this.list = []
    this.isTab = to.params.type_id
    this.title = to.params.title
    this.keyPath = [0]
    this.getList()
    next()
  }

}
</script>
<style lang="scss">
  .bizParam{
    .main-box{
      .ant-tabs-tab{
        text-align: left;
      }
      .ant-menu-light{
        height: 80%;
        border-right: 2px solid #e8e8e8;
        
      }
      .ant-menu-item:hover{
        background-color: #e6fff6;
      }
    }
    .systemMeun{
      display: flex;
      .ant-dropdown-trigger{
        height: 100%;
      }
      .ant-popover-inner-content{
        padding:5px 0;
      }
      .ant-menu-item{
        padding: 0px;
      }
    }
    .systemMeun-left-nav{
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid #eee;
      border-right: 2px solid #e8e8e8;
    }
    .systemMeun-left-item{
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        padding-left:10px;
        cursor: pointer;
        &-icon{
            position: absolute;
            right: 10px;
            cursor: pointer;
            .anticon{
              margin-right: 0px !important;
            }
        }
        &-count{
            position: absolute;
            right: 25px;
            font-size: 12px;
            color: #999;
        }
    }
    .bizParam-box{
      display: flex;
    }
    .ant-form-item{
      margin-bottom: 12px;
    }
    .ant-menu-item-selected{
      box-shadow: 2px 0px 0px #00cca2
    }
  }
</style>