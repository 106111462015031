<template>
  <a-modal title="关联员工" v-model="visible" centered :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="400px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form>
      <a-form-item label="选择员工:">
          <a-select v-model="employee_id" style="width: 350px" placeholder="请选择" @search="handleSearch" mode="multiple" showSearch :filterOption="filterOption">
                <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
            </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template> 

<script>
    export default {
        name:"relation",
        inject: ['parent'],
        data() {
            return {
                visible: false,
                confirmLoading:false,
                consultants:[],
                employee_id:[],
            }
        },
        props:['roleId'],
        created () {
            this.visible = true
            this.getConsultant()
        },
        beforeDestroy () {
            this.visible = false
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getConsultant(val) {
                let obj = {q:val}
                let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj,data:{active:true}})
                this.consultants = res.data
            },
            async handleOk() {
                this.confirmLoading=true
                try {
                    if(this.employee_id.length !=0){
                        let obj = {
                            group_id:this.roleId,
                            employee_id:this.employee_id
                        }
                        let res = await this.$store.dispatch('employeeGroupRelatedToAction', {data:obj})
                        this.$message.success('操作成功!')
                        this.parent.hideRelation(1)
                    }else{
                        this.$message.warning('请选择!')
                    }
                    
                } catch {
                }
            },
            handleCancel(){
                this.parent.hideRelation()
            },
            handleSearch(val){
                this.getConsultant(val)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>