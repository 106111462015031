<template>
  <a-modal :title="ModalTitle" v-model="visible" centered :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="400px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form>
      <a-form-item label="角色名称">
        <a-input v-model='inputValue' placeholder="请输入"></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
    export default {
        name:'RoleOperation',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                inputValue:'',
                confirmLoading:false,
            }
        },
        props:['ModalTitle','roleName','isTab','isEdit','roleId'],
        created () {
            this.visible = true
            this.inputValue = this.roleName
        },
        beforeDestroy () {
            this.visible = false
        },
        methods: {
            async handleOk() {
      this.confirmLoading=true;
                try {
                    if(this.inputValue){
                        if(this.isEdit == 0){
                            let type_id = this.isTab;
                            let obj = {
                                type_id:type_id.toString(),
                                group_name:this.inputValue,
                            }
                            let res = await this.$store.dispatch('employeeGroupAddAction', {data:obj})
                        }else{
                            let obj = {
                                group_id:this.roleId.toString(),
                                group_name:this.inputValue,
                            }
                            let res = await this.$store.dispatch('employeeGroupUpdateAction', {data:obj})
                        }
                        this.$message.success('操作成功!')
                        this.parent.hideRoleOperation(1)
                    }else{
                        this.$message.warning('请输入名称!')
                    }
                    
                } catch {
                }
            },
            handleCancel(){
                this.parent.hideRoleOperation()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>