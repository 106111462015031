<template>
    <div class="zcxy zl-list">
        <div class="clearfix table-tools">
        <div class="buttons">
            <a-button @click="showRelation" type="primary">关联员工</a-button>
        </div>
        </div>
        <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id"
            :columns="columns" :dataSource="list">
            <template slot="index" slot-scope="text, record , index">
                <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="group_name" slot-scope="text">
                <div v-for="(item,index) in text" :key="index">{{item}}</div>
            </template>
            <template slot="action" slot-scope="text,record">
                <a @click="toCancel(record)" href="javascript:;">取消关联</a>
            </template>
            <template slot="status" slot-scope="text,record">
                <a-switch checkedChildren="启" unCheckedChildren="禁" :checked="text === 1" @change="changeStatus(record)"/>
            </template>
        </a-table>
        </div>
        <div class="page">
        <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
            </template>
            </a-pagination>
        </div>
        <relation v-if="isRelation" :roleId='roleId'/>
    </div>
</template>

<script>
const columns = [
{ title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index' ,fixed: 'left'},
  { title: '员工姓名', dataIndex: 'employee_name', key: 'name' ,fixed: 'left'},
  { title: '性别', dataIndex: 'employee_gender', key: 'age'},
  { title: '手机号码', dataIndex: 'employee_cellphone', key: '1' },
  { title: '所属部门', dataIndex: 'department', key: 'department'},
  { title: '直属上级', dataIndex: 'parent_name', key: 'parent_name' },
  { title: '账户角色', dataIndex: 'group_name', key: 'group_name' , scopedSlots: { customRender: 'group_name' }, ellipsis:true},
  { title: '兼职/全职', dataIndex: 'employee_is_parttime', key: '5' ,align:'center'},
  { title: '账户状态', dataIndex: 'employee_status', key: '11', width: 80, scopedSlots: { customRender: 'status' }},
  { title: '操作', key: 'operation', width: 120, scopedSlots: { customRender: 'action' },align:"right"},
]
import tableMixins from '@/common/mixins/table'
import relation from './relation'
    export default {
        name:"RoleStaff",
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                loading: false,
                list:[],
                columns,
                isRelation:false,
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {},
                    "sort": ''
                },
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                current: 1,
                
            }
        },
        props:['roleId','isTab'],
        components:{relation},
        created(){
            this.pageParams.perPage = this.$ls.get('perPage') || 10
            this.searchParams['per-page'] = this.pageParams.perPage
        },
        methods:{
            async getList(){
                this.loading = true
                this.searchParams.search.group_id = this.roleId
                let res = await this.$store.dispatch('employeeAccountAction', {data:this.searchParams})
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            toCancel(record){
                let that = this;
                this.$confirm({
                    title: `您确定要取消关联?`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let obj = {
                            group_id:that.roleId,
                            employee_id:record.employee_id
                        }
                        let res = await that.$store.dispatch('employeeGroupRemoveAction', { data: obj})
                        if (res) {
                        that.getList()
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            showRelation(){
                this.isRelation = true
            },
            hideRelation(num){
                if(num == 1){
                    this.getList()
                }
                this.isRelation = false
            },
            changePage(page, pageSize) {
                this.searchParams.page = page
                this.getList()
            },
            onShowSizeChange(current, size) {
                this.searchParams['per-page'] = size
                this.$ls.set('perPage',size)
                this.getList()
            },
        },
        watch: {
            roleId(newValue, oldValue) {
                this.getList()
            },
            isTab(){
                this.getList()
            }
        },
    }
</script>